<template>
  <div class="page">
    <div class="box1">
      <div class="main_box">
        <div class="ttl">电子合同解决方案</div>
        <div class="sub_ttl">
          一站式电子合同服务，快速签约，轻松管理海量劳务合同
        </div>
        <a
          class="btn"
          href="http://p.qiao.baidu.com/cps/chat?siteId=13160731&userId=27403999&siteToken=839057cc7afae96bcdd508339dc7a783"
          target="_blank"
        >
          咨询服务
        </a>
      </div>
    </div>
    <div class="box5">
      <div class="title">传统合同的痛点</div>
      <div class="main_box" flex="main:justify">
        <div class="col c1">
          <div class="info">
            <div class="ttl">签约成本高</div>
            <div class="desc">
              分包及班组多，工人数量庞大，线下协调纸质合同签约时间成本高、人力成本高，签约工作量极大
            </div>
          </div>
        </div>
        <div class="col c2">
          <div class="info">
            <div class="ttl">签约效率低</div>
            <div class="desc">
              劳务公司合同章大多不在施工现场，签约周期长，且工人流动性大，签署效率低
            </div>
          </div>
        </div>
        <div class="col c3">
          <div class="info">
            <div class="ttl">监管难风险高</div>
            <div class="desc">
              分包和工人未签署合同，甚至代签合同，存在风险，但无法监管且无法了解各分包签约进度
            </div>
          </div>
        </div>
        <div class="col c4">
          <div class="info">
            <div class="ttl">回溯管理难</div>
            <div class="desc">
              管理海量纸质资料，保存及管理要求高、难度大，极易丢失，迎接检查等需要回溯查找时，寻找困难且效率低
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box4">
      <div class="title">一站式电子合同服务</div>
      <div class="main_box" flex="main:justify cross:center">
        <div class="row_tabs" flex-box="0">
          <div
            :class="['item', box4.crtIndex === idx ? 'z-crt' : '']"
            flex="dir:left"
            v-for="(s, idx) in box4.slides"
            :key="idx"
            @click="box4.swiper.slideTo(idx)"
          >
            <div class="num">{{ idx + 1 }}</div>
            <div>
              <div class="ttl">{{ s.ttl }}</div>
              <div class="desc">{{ s.desc }}</div>
            </div>
          </div>
        </div>
        <div class="phone_swp" flex-box="0">
          <swiper
            @ready="(sw) => (box4.swiper = sw)"
            @slideChange="() => (box4.crtIndex = box4.swiper.realIndex)"
            class="swp swiper-no-swiping"
            :options="{ autoplay: { delay: 4000 } }"
          >
            <swiper-slide
              class="sld"
              v-for="(s, idx) in box4.slides"
              :key="idx"
            >
              <img class="pic" :src="s.pic" alt="s.ttl" />
            </swiper-slide>
          </swiper>
          <div class="pagination" flex="main:center">
            <div
              :class="['dot', box4.crtIndex === i ? 'z-crt' : '']"
              @click="box4.swiper.slideTo(i)"
              v-for="(item, i) in box4.slides"
              :key="i"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="title">工人劳务费管理</div>
      <div class="main_box" flex="main:justify cross:center">
        <div class="phone_swp" flex-box="0">
          <swiper
            @ready="(sw) => (box2.swiper = sw)"
            @slideChange="() => (box2.crtIndex = box2.swiper.realIndex)"
            class="swp swiper-no-swiping"
            :options="{ autoplay: { delay: 4000 } }"
          >
            <swiper-slide
              class="sld"
              v-for="(s, idx) in box2.slides"
              :key="idx"
            >
              <img class="pic" :src="s.pic" alt="s.ttl" />
            </swiper-slide>
          </swiper>
          <div class="pagination" flex="main:center">
            <div
              :class="['dot', box2.crtIndex === i ? 'z-crt' : '']"
              @click="box2.swiper.slideTo(i)"
              v-for="(item, i) in box2.slides"
              :key="i"
            />
          </div>
        </div>
        <div class="row_tabs" flex-box="0">
          <div
            :class="['item', box2.crtIndex === idx ? 'z-crt' : '']"
            flex="dir:left"
            v-for="(s, idx) in box2.slides"
            :key="idx"
            @click="box2.swiper.slideTo(idx)"
          >
            <div class="num">{{ idx + 1 }}</div>
            <div>
              <div class="ttl">{{ s.ttl }}</div>
              <div class="desc">{{ s.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="title">电子合同签署流程</div>
      <div class="main_box">
        <img class="pic" src="@/assets/img/流程.png" alt="流程" />
      </div>
    </div>
    <div class="trial_bar">
      <div class="main_box" flex="main:justify cross:center">
        <div class="ttl">为您打造一套数智工程管理解决方案</div>
        <div class="btn" @click="showTrialModal">申请体验</div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Footer from "@/components/Footer.vue";

const baseUrl = `${process.env.BASE_URL}public/img/手机截图`;

const box2 = {
  swiper: null,
  crtIndex: 0,
  slides: [
    {
      ttl: "自动生成工资支付表",
      desc: "自动生成工资表单。历史记录快速查看之前制作的支付表，无需重复制作",
      pic: `${baseUrl}/电子合同_2_1.png`,
    },
    {
      ttl: "工资表审核",
      desc: "各分包管理员审核完，工资支付表将自动提交到项目管理员审核",
      pic: `${baseUrl}/电子合同_2_2.png`,
    },
    {
      ttl: "工资确认提醒",
      desc: "发送工资确认消息弹窗，提醒工人及时确认工资",
      pic: `${baseUrl}/电子合同_2_3.png`,
    },
    {
      ttl: "确认记录追溯",
      desc: "确认记录可查询，快速定位和调取文件，易于管理",
      pic: `${baseUrl}/电子合同_2_4.png`,
    },
    {
      ttl: "工资安全保障",
      desc: "由工人本人进行确认，避免了代签、代领的情况，有效规避劳务纠纷。",
      pic: `${baseUrl}/电子合同_2_5.png`,
    },
    {
      ttl: "数据导出",
      desc: "汇总展示班组工资，可一键导出查看详情，助于成本控制",
      pic: `${baseUrl}/电子合同_2_6.png`,
    },
  ],
};

const box4 = {
  swiper: null,
  crtIndex: 0,
  slides: [
    {
      ttl: "一键生成合同模板",
      desc: "在线选择电子合同模版，系统根据考勤规则和工人信息自动生成合同",
      pic: `${baseUrl}/电子合同_1_1.png`,
    },
    {
      ttl: "在线完成签约",
      desc: "工人进场签约流程大幅度简化，可以更好地把控时间消耗和现场秩序",
      pic: `${baseUrl}/电子合同_1_2.png`,
    },
    {
      ttl: "智能电子签名",
      desc: "可靠合规具有法律效力，防篡改防假冒确保签署主体真实性",
      pic: `${baseUrl}/电子合同_1_3.png`,
    },
    {
      ttl: "快速查询及调用",
      desc: "企业和工人随时在线可查询合同，简化文件调取和查询工作",
      pic: `${baseUrl}/电子合同_1_4.png`,
    },
    {
      ttl: "总分包视角",
      desc: "分包签署进度和数据汇总查看，确保签约到位，避免劳务纠纷",
      pic: `${baseUrl}/电子合同_1_5.png`,
    },
  ],
};

export default {
  components: {
    Footer,
  },
  data() {
    return {
      box2,
      box4,
    };
  },
  methods: {
    ...mapActions(["showTrialModal"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";

.box1 {
  background: url("~@/assets/bg/banner 6.jpg") no-repeat center center;
  background-size: cover;
  height: 32rem;
  overflow: hidden;

  .ttl {
    margin-top: 13.8rem;
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    line-height: 2.8rem;
    letter-spacing: 0.2rem;
  }

  .sub_ttl {
    margin-top: 1.1rem;
    font-size: 1rem;
    color: #fff;
    line-height: 1.5rem;
  }

  .btn {
    margin-top: 3rem;
    display: block;
    width: 6.6rem;
    height: 2rem;
    background-color: #2574ff;
    border-radius: 0.4rem;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    color: #fff;
    line-height: 2rem;

    &:hover {
      background-color: #045af2;
    }
  }
}

.box5 {
  padding: 7rem 0;
  background-color: #fff;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    margin-top: 3rem;

    .col {
      flex-shrink: 0;
      position: relative;
      width: 15.5rem;
      height: 20rem;
      color: #fff;
      background-size: cover;
      background-position: center center;

      &.c1 {
        background-image: url("~@/assets/bg/签约成本高.jpg");
      }

      &.c2 {
        background-image: url("~@/assets/bg/签约效率低.jpg");
      }

      &.c3 {
        background-image: url("~@/assets/bg/监管难风险高.jpg");
      }

      &.c4 {
        background-image: url("~@/assets/bg/回溯管理难.jpg");
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.6)
        );
      }

      .info {
        position: relative;
        z-index: 1;
        margin: 12.9rem 1.25rem 0;
      }

      .ttl {
        font-size: 1.2rem;
        font-weight: bold;
        color: #fff;
        line-height: 1.7rem;
      }

      .desc {
        margin-top: 0.6rem;
        font-size: 0.8rem;
        color: #fff;
        line-height: 1.2rem;
      }
    }
  }
}

.box2 {
  overflow: hidden;
  background-color: #fff;
  padding: 7rem 0;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    margin-top: 7rem;
    width: 56.35rem;
    height: 31rem;
    background: url("~@/assets/bg/装饰背景.png") no-repeat center center;
    background-size: 100% 100%;
    padding-left: 5rem;
    box-sizing: border-box;
  }
}

.box4 {
  overflow: hidden;
  background-color: #f0f1f6;
  padding: 7rem 0;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    margin-top: 7rem;
    width: 56.35rem;
    height: 31rem;
    background: url("~@/assets/bg/装饰背景2.png") no-repeat right center;
    background-size: auto 100%;
    padding-right: 4.5rem;
    box-sizing: border-box;
  }
}

.box3 {
  overflow: hidden;
  background-color: #f0f1f6;
  padding-top: 7rem;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    padding: 5rem 0 5.5rem;

    .pic {
      display: block;
      width: 100%;
    }
  }
}

.trial_bar {
  background-color: #f0f1f6;
  padding: 4.5rem 0 1.6rem;

  .main_box {
    background: url("~@/assets/bg/申请体验背景.png") no-repeat center center;
    background-size: cover;
    height: 7rem;
  }

  .ttl {
    margin-left: 4rem;
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff;
    line-height: 2.5rem;
    letter-spacing: 0.15rem;
  }

  .btn {
    margin-right: 4rem;
    width: 7rem;
    height: 2.4rem;
    background-color: #fff;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: #0078f0;
    line-height: 2.4rem;
    cursor: pointer;

    &:hover {
      background-color: #dce8ff;
    }
  }
}

.main_box {
  width: $mainWidth;
  margin: auto;
}

.row_tabs {
  width: 24rem;

  .item {
    margin-bottom: 2rem;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &.z-crt {
      .num {
        opacity: 1;
      }

      .ttl {
        font-size: 1.2rem;
        line-height: 1.8rem;
        margin-top: 0;
      }
    }

    .num {
      width: 1.7rem;
      height: 1.7rem;
      line-height: 1.7rem;
      text-align: center;
      background-color: #0078f0;
      border-radius: 0.6rem;
      opacity: 0.2;
      font-size: 1rem;
      font-weight: bold;
      color: #fff;
      margin-right: 0.8rem;
    }

    .ttl {
      font-size: 0.9rem;
      font-weight: bold;
      color: #1a1a1a;
      line-height: 1.35rem;
      margin-top: 0.2rem;
    }

    .desc {
      margin-top: 0.4rem;
      font-size: 0.7rem;
      color: #9a9a9a;
      line-height: 1.1rem;
    }
  }
}

.phone_swp {
  position: relative;
  width: 14.9rem;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 30.55rem;
    z-index: 1;
    background: url("~@/assets/bg/1手机边框.png") center center no-repeat;
    background-size: contain;
  }

  .swp {
    width: 13rem;
    height: 28.15rem;
    margin: 0.9rem auto 0;

    .pic {
      display: block;
      width: 100%;
    }
  }

  .pagination {
    margin-top: 1.8rem;

    .dot {
      width: 0.6rem;
      height: 0.15rem;
      opacity: 0.4;
      background-color: #2574ff;
      border-radius: 0.1rem;
      cursor: pointer;
      transition: opacity 0.3s;
      margin-right: 0.4rem;

      &:last-child {
        margin-right: 0;
      }

      &.z-crt {
        opacity: 1;
      }
    }
  }
}
</style>
